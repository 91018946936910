@font-face {
    font-family: 'San Francisco';
    font-weight: 700;
    src: url('/public/fonts/sanfranciscodisplay-bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'San Francisco';
    font-weight: 800;
    src: url('/public/fonts/sanfranciscodisplay-heavy-webfont.woff') format('woff');
}

@font-face {
    font-family: 'San Francisco';
    font-weight: 200;
    src: url('/public/fonts/sanfranciscodisplay-light-webfont.woff') format('woff');
}

@font-face {
    font-family: 'San Francisco';
    font-weight: 500;
    src: url('/public/fonts/sanfranciscodisplay-medium-webfont.woff') format('woff');
}

@font-face {
    font-family: 'San Francisco';
    font-weight: normal;
    src: url('/public/fonts/sanfranciscodisplay-regular-webfont.woff') format('woff');
}

@font-face {
    font-family: 'San Francisco';
    font-weight: 600;
    src: url('/public/fonts/sanfranciscodisplay-semibold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'San Francisco';
    font-weight: 300;
    src: url('/public/fonts/sanfranciscodisplay-thin-webfont.woff') format('woff');
}

@font-face {
    font-family: 'San Francisco';
    font-weight: 100;
    src: url('/public/fonts/sanfranciscodisplay-ultralight-webfont.woff') format('woff');
}
